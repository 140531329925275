// import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

export const SiteContext = React.createContext()

const Provider = (props) => {
  const [peopleSearchOpen, setPeopleSearchOpen] = useState(false)
  const [postSearchOpen, setPostSearchOpen] = useState(false)

  return (
    <SiteContext.Provider
      value={{
        peopleSearchOpen,
        setPeopleSearchOpen,
        postSearchOpen,
        setPostSearchOpen,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@knowit-jamstack/gatsby-theme-knowit/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-lujatalo-headless","short_name":"Lujatalo","start_url":"/","background_color":"#fff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/luja-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"934b0b10a2722820cf1852e40c1cdfc7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MTVZLPB","includeInDevelopment":false,"routeChangeEventName":"virtualpageview","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"searchAndReplace":[{"search":"https://lujatalomaster.gatsbyjs.io","replace":"https://www.lujatalo.fi"},{"search":"https://lujatalomaster.gtsb.io","replace":"https://www.lujatalo.fi"}],"url":"https://luja-cms.fi-t.seravo.com/lujatalo/graphql/","verbose":true,"schema":{"timeout":960000,"perPage":100,"requestConcurrency":2,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"useGatsbyImage":true,"placeholderType":"dominantColor","imageQuality":70,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":true},"type":{"Page":{},"Post":{"limit":100000},"Reference":{},"Person":{},"Unit":{},"Tag":{},"Taxonomy":{},"Redirect":{"limit":100000},"MenuItem":{},"Menu":{},"Comment":{},"PostFormat":{},"UserRole":{},"User":{},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/static/*":["Cache-Control: public, max-age=31536000, immutable"],"/images/*":["Cache-Control: public, max-age=31536000, immutable"],"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"]},"allPageHeaders":[],"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

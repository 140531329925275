exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/CategoryArchive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */),
  "component---src-templates-page-basic-js": () => import("./../../../src/templates/PageBasic.js" /* webpackChunkName: "component---src-templates-page-basic-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-archive-js": () => import("./../../../src/templates/PostArchive.js" /* webpackChunkName: "component---src-templates-post-archive-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/Reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-unit-js": () => import("./../../../src/templates/Unit.js" /* webpackChunkName: "component---src-templates-unit-js" */)
}

